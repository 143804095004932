import "../styles/styles.scss";

import 'glightbox/dist/css/glightbox.css';
import GLightbox from 'glightbox';
import LazyLoad from 'vanilla-lazyload';

const burgerMenu = () => {
    const burgerMenuBtn = document.querySelector('.bar__nav-mobile-btn');
    const burgerMenuWrapper = document.querySelector('.bar__nav-mobile-wrapper');

    burgerMenuBtn.addEventListener('click', () => {
        if (burgerMenuWrapper.classList.contains('show-menu')) {
            burgerMenuBtn.classList.remove('active');
            burgerMenuWrapper.classList.remove('show-menu');
        } else {
            burgerMenuBtn.classList.remove('active');
            burgerMenuWrapper.classList.add('show-menu');
        }
    });
};

const scrollTo = () => {
    const scrollUpBtn = document.querySelector('.bar__scroll-up');

    scrollUpBtn.addEventListener('click', function() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
};

const loadMore = () => {
    const getContent = async (url, pageNumber) => {
        const contentWrapper = document.querySelector('.content__main');

        try {
            const response = await fetch(`/${url}/?page=${pageNumber}`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            const data = await response.text();
            contentWrapper.insertAdjacentHTML('beforeend', data);
            return true;
        } catch (error) {
            console.error('Произошла непредвиденная ошибка', error);
            return false;
        }
    };

    const handleLoadMoreClick = async (event) => {
        const button = event.target;
        const url = button.dataset.url;
        const pageNumber = parseInt(button.dataset.page);

        const loaded = await getContent(url, pageNumber);
        if (loaded) {
            button.style.display = 'none';
        }
    };

    document.addEventListener('click', async event => {
        if (event.target.matches('.btn--more')) {
            await handleLoadMoreClick(event);
        }
    });
};

const copyBtn = () => {
    const copyButtons = document.querySelectorAll('.page-content-code__copy');

    copyButtons.forEach(function(button) {
        button.addEventListener('click', function() {
            const preElementId = this.getAttribute('data-source-code');
            const preElement = document.getElementById(preElementId);
            const preText = preElement.innerText || preElement.textContent;
            const label = this.querySelector('.copy-label');

            const textarea = document.createElement('textarea');
            textarea.style.position = 'fixed';
            textarea.style.opacity = 0;
            textarea.value = preText;
            document.body.appendChild(textarea);

            textarea.select();
            textarea.setSelectionRange(0, 99999);

            document.execCommand('copy');

            document.body.removeChild(textarea);

            label.textContent = 'скопировано в буфер обмена';

            setTimeout(() => {
              label.textContent = 'копировать';
            }, 2000);
        });
    });
};

const imageZoom = () => {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
    });

    const lightbox = GLightbox({
        // touchNavigation: false,
        loop: false,
        selector: '.image-zoom',
        openEffect: 'zoom',
        closeEffect: 'zoom',
        zoomable: true,
        draggable: false,
        dragTolerance: 1,
        width: 'auto',
        height: 'auto',
    });

    lazyLoadInstance.update();
    lightbox;
};

document.addEventListener('DOMContentLoaded', function()
{
    burgerMenu();
    scrollTo();
    loadMore();
    copyBtn();
    imageZoom();
});
